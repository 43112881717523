<template>
  <div class="page-container">
    <!-- Section Who We Are Start -->
    <div class="section-wrapper section-who-we-are">
      <div v-intersect.once="onIntersect" class="content-wrapper">
        <Content1
          :title="$t('whoWeAre[0].title')"
          :description="
            isMobile
              ? removeHtmlTags($t('whoWeAre[0].description'))
              : $t('whoWeAre[0].description')
          "
        ></Content1>

        <v-scroll-y-transition
          :group="true"
          tag="div"
          class="row no-gutters mt-6 pa-md-10 about-us-list"
        >
          <template v-for="(i, index) in aboutUsList">
            <v-col
              v-if="isIntersecting"
              :key="index"
              cols="12"
              md="6"
              class="pt-6 pa-md-12"
              :class="{ 'item-wrapper divider': !isMobile }"
              :style="`
                  transition-delay: 1.${index}s !important;
                  transition-duration: 0.5s !important;`"
            >
              <div
                v-if="i.type == 'text'"
                class="d-flex align-center wh-100 px-6 mb-4"
              >
                <div
                  v-html="$t('whoWeAre[1].1')"
                  class="font-cormorant title--text text-h4 text-lg-h3"
                ></div>
              </div>
              <v-row no-gutters v-else class="px-md-6 mb-4">
                <v-col cols="2" md="12">
                  <img
                    :height="isMobile ? 45 : ''"
                    :src="`${shared.BASE_URL}img/svg/${i.svg}.svg`"
                  />
                </v-col>
                <v-col cols="10" md="12">
                  <div
                    class="font-weight-bold my-md-4 text-body-1 text-md-h6 mb-3 mb-md-0"
                  >
                    {{ $t(`whoWeAre[1].${i.text}`) }}
                  </div>
                  <div class="d-md-none label--text text-body-2 text-lg-body-1">
                    {{ $t(`whoWeAre[2].${i.text}`) }}
                  </div>
                </v-col>
                <v-col cols="2" md="0"></v-col>
                <v-col
                  cols="10"
                  md="12"
                  class="d-none d-md-flex label--text text-body-2 text-lg-body-1"
                >
                  {{ $t(`whoWeAre[2].${i.text}`) }}
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-scroll-y-transition>
      </div>
    </div>
    <!-- Section Who We Are End -->

    <!-- Section Contact Us Now Start -->
    <ContactUsNow></ContactUsNow>
    <!-- Section Contact Us Now End -->
  </div>
</template>

<script>
import ContactUsNow from '@/components/section/ContactUsNow'
import { uiHelper } from '@/utils'
import { SHARED } from '@/constants'

export default {
  name: 'AboutUs',
  components: {
    ContactUsNow
  },
  data: () => ({
    shared: SHARED,
    aboutUsList: [
      {
        type: 'text'
      },
      {
        svg: 'handle',
        text: 2
      },
      {
        svg: 'business-presentation',
        text: 3
      },
      {
        svg: 'handshake',
        text: 4
      }
    ],
    isIntersecting: false
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersecting = isIntersecting
      }
    },
    removeHtmlTags(string) {
      return uiHelper.removeHtmlTags(string)
    }
  }
}
</script>

<style lang="scss">
.section-who-we-are {
  .divider {
    border-right: 1px solid var(--v-greyDivider-base);
    &:nth-child(2),
    &:nth-child(4) {
      border: unset !important;
    }
  }
  .about-us-list {
    .item-wrapper:nth-child(odd) {
      padding-left: 4rem !important;
    }
  }
}
</style>
